import React from "react"
import Container from "react-bootstrap/Container"
import Row from "react-bootstrap/Row"
import Col from "react-bootstrap/Col"
import "./styles/about-us.styles.scss"
import Footer from "../components/Layout/Footer"
import NavBar from "../components/Layout/navBar/NavBar"

const AboutUs = () => (
  <>
    <NavBar />
    <Container fluid className="about-us-main-container p-0">
      <Container className="d-flex align-items-center">
        <Row>
          <Col className="text-center mx-auto" md={10}>
            <h1 className="pt-4 h1-header text-white">About Us</h1>
            <p>
              Salt Point Finance dba Pave Credit is a subsidiary of Kashia
              Services, a wholly owned business entity of the Kashia Band of
              Pomo Indians of the Stewarts Point Rancheria.{" "}
            </p>
          </Col>
        </Row>
      </Container>
    </Container>
    <Footer />
  </>
)

export default AboutUs
